<template>
	<div class="project-case">
	  <img
	    class="pic1"
	    referrerpolicy="no-referrer"
	    :src="require('../assets/banner.png')"
	  />
	  <router-view></router-view>
	   <el-backtop target=".project-case"></el-backtop>
	</div>
	
</template>

<script>
	
	export default {
		name:'DynamilCall',	
		
	}
</script>

<style scoped>
	.pic1 {
	  height: 600px;
	  width: 100%;
	  display: block;
	}
	.project-case{
	  padding-top: 80px;
	  min-height: 200vh;
	 
	
	}
</style>
